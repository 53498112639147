import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";

const NotFoundPage = () => {
  return (
    <Layout>
      <main>
        <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="max-w-max mx-auto">
            <main className="sm:flex mt-32 h-[500px]">
              <p className="text-4xl font-extrabold text-e-blue sm:text-5xl">
                404
              </p>
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                    Page not found
                  </h1>
                  <p className="mt-1 text-base text-gray-500">
                    Please check the URL in the address bar and try again.
                  </p>
                </div>
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                  <Link
                    to="/"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium  shadow-sm text-white bg-e-blue hover:bg-e-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-e-blue"
                  >
                    Go back home
                  </Link>
                  <Link
                    to="/#contact"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium  text-e-blue bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-e-blue"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </main>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
